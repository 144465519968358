<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">


          <md-card :class="{'back-success': $route.params.messageType === 'success'}" class="md-primary" v-if="$route.params.message" >
            <md-card-header>
              <md-card-header-text>
                
                <div class="md-subheading">
                  <md-icon class="mr-2" >done</md-icon>
                  <span>{{$route.params.message}}</span>
                </div>
              </md-card-header-text>
            </md-card-header>
          </md-card>
          <md-card>
              <form action="#" @submit.prevent="submit">
                <md-card-header>
                    <h1 class="md-title">Login</h1>
                </md-card-header>

                <md-card-content>
                    <CRow>
                        <CCol sm="12">
                            <CRow>
                              <CCol sm="6">
                                <md-icon class="md-icon-40 mr-4 mb-2" :class="{'up-text-secondary': emailLogin}">email</md-icon>
                                <md-switch v-model="emailLogin" class="md-primary">Login with email</md-switch>
                              </CCol>
                              <CCol sm="6">
                                <md-icon class="md-icon-40 mr-4 mb-2" :class="{'up-text-secondary': phoneLogin}">phone</md-icon>
                                <md-switch v-model="phoneLogin" class="md-primary">Login with phone</md-switch>

                              </CCol>
                            </CRow>
                            <md-field :class="getValidationClass('email')" v-if="emailLogin">
                                <label>email</label>
                                <md-input v-model="email"></md-input>
                                <span class="md-error" v-if="!$v.email.required">email is required</span>
                                <span class="md-error" v-if="!$v.email.email">Not valid email address</span>
                            </md-field>
                            <md-field :class="getValidationClass('phone')" v-if="!emailLogin">
                                <label>Phone</label>
                                <md-input v-model="phone"></md-input>
                                <span class="md-error" v-if="!$v.phone.required">Phone is required</span>
                                <span class="md-helper-text md-subheading" v-if="smsSent">SMS sent successfully</span>
                            </md-field>

                            <md-field :class="getValidationClass('password')" v-if="emailLogin">
                                <label>password</label>
                                <md-input v-model="password" type="password"></md-input>
                                <span class="md-error" v-if="!$v.password.required">Password is required</span>
                                <span class="md-error" v-if="!$v.password.minLength">Password must be at least 6 characters long</span>
                            </md-field>
                            <md-field :class="getValidationClass('verification')" v-if="(!emailLogin && smsSent ) || ( emailLogin && verificationId)" >
                                <label>Enter verification code</label>
                                <md-input v-model="verification" @keyup="loginUser" :disabled="checkingVerificationCode"></md-input>
                                <span class="md-error" v-if="!$v.verification.required">Verification is required</span>
                                <span class="md-error" v-if="!$v.verification.minLength">Enter 6 digits combination</span>
                                <span class="md-helper-text up-text-cancelled" v-if="verificationError">{{ verificationError }}</span>
                            </md-field>
                            <md-field :class="getValidationClass('recaptcha')" class="no-line" >
                              <div id="recaptcha-container"></div>
                              <span class="md-error" v-if="!$v.recaptcha.required">reCAPTCHA is required</span>
                            </md-field>
                            <md-field class="md-invalid" v-if="error">
                              <span class="md-error md-subheading">{{ error }}</span>
                            </md-field>
                            
                        </CCol>
                        
                    </CRow>
                    <CRow class="mt-5">
                          <CCol sm="12">
                            
                            <a @click="forgotPassword">Forgot your password ?</a>
                            <md-button class="md-raised full-width-button md-primary" type="submit" v-if="emailLogin">Login</md-button>
                            <md-button class="md-raised full-width-button md-primary" type="submit" v-if="!emailLogin" :disabled="checkingVerificationCode || sendingVerificationCode ">
                               {{ checkingVerificationCode ? 'Checking code' : ''}} 
                               {{ sendingVerificationCode ? 'Sending verification code' : '' }}
                               {{ !checkingVerificationCode && !sendingVerificationCode && smsSenderCounter === 0 ? 'Send SMS verification code' : '' }}
                               {{ !checkingVerificationCode && !sendingVerificationCode && smsSenderCounter > 0 ? 'Resend SMS verification code' : '' }}
                                <md-progress-bar md-mode="indeterminate" v-show="checkingVerificationCode || sendingVerificationCode"></md-progress-bar>
                            </md-button>
                        </CCol>
                    </CRow>
                    <p>Do not have an account?</p>
                    <md-button class="md-raised full-width-button" @click="register">Register</md-button>
                </md-card-content>
              </form>
          
          </md-card>




          
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import newAxios from "./../../helpers/axios"
import axios from "./../../helpers/axios";

import { validationMixin } from 'vuelidate'
import {
    required,
    between,
    email,
    minLength,
    maxLength,
requiredIf,
} from 'vuelidate/lib/validators'

export default {
  name: "Login",
  mixins: [validationMixin],
  data() {
    return {
      email: "",
      password: "",
      error: null,
      recaptcha:null,
      emailLogin:true,
      phoneLogin: false,
      phone:null,
      verification: null,
      smsSent: false,
      verificationError: null,
      multifactorResolver: null,
      verificationId: null,
      checkingVerificationCode: false,
      sendingVerificationCode: false,
      smsSenderCounter: 0

    };
  },
  beforeMount() {
    if(this.user.loggedIn === true)
        this.$router.replace({ name: "App" })
  },
  mounted(){

    window.recaptchaVerifier = new this.$firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'normal',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
        console.log(response)
        this.recaptcha = response
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
        this.recaptcha = null
      }
    });

    recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });

  },
  methods: {
    validate(){
        let email,password,recaptcha,phone,verification;
        email = this.$v.email.$invalid;
        password = this.$v.password.$invalid;
        recaptcha = this.$v.recaptcha.$invalid;
        phone = this.$v.phone.$invalid;
        verification = this.$v.verification.$invalid;

        
        return !email && !password && !recaptcha && !phone && !verification? true : false ;


    },
    getValidationClass (fieldName) {
        let field = null;

        
        field = this.$v[fieldName];
        
        
        
        if (field) {
            return {
                'md-invalid': field.$invalid && field.$dirty
            }
        }
    },
    register () {
    
      this.$router.push({
        path: `register`,
      })
    },
    forgotPassword () {
    
      this.$router.push({
        path: `forgot-password`,
      })
    },
    async checkVerificationCode() {
      return new Promise((resolve, reject) => {
        const code = this.verification
        confirmationResult.confirm(code).then((result) => {
          // User signed in successfully.
          const user = result.user;
          console.log(user)
          resolve(user)
          // ...
        }).catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
          this.checkingVerificationCode = false;
          this.verificationError = error.message
          this.recaptcha = null
          reject(error)
          
        });
        
      })
    },
    async getCredential(verificationId, code) {
      return new Promise(async (resolve, reject) => {
        let credential
        //credential = await this.$firebase.auth.PhoneAuthProvider.credential(verificationId, code)
        credential = await this.$firebase.auth.PhoneAuthProvider.credential(verificationId, '123455')
        console.log(credential)
        resolve(credential)
      })
    },
    resetSmsCodeSending() {
      this.verificationError = null
      this.verification = null
      this.smsSent = false
      this.error = null
    },
    async loginUser() {
      let verification ,credential
      let th = this;
      //this.resetSmsCodeSending()
      if(this.verification && this.verification.length == 6) {
              
        if(!this.emailLogin && this.smsSent) {
          // Phone login
            this.checkingVerificationCode = true;

            verification = await this.checkVerificationCode()
            console.log(verification)
            credential = await this.getCredential(confirmationResult.verificationId, this.verification)
            //credential = await this.$firebase.auth.PhoneAuthProvider.credential(confirmationResult.verificationId, this.verification)
            //console.log(credential)

            console.log(this.user)
            
            if(this.user.loggedIn === true) {
              th.$router.replace({ name: "App" });
            } else {

            }
            // this.$firebase.auth().signInWithCredential(credential)
            //                   .then((data) => {
            //                         console.log(data);
            //                         this.checkingVerificationCode = false;
            //                         th.$router.replace({ name: "App" });
                                  
            //                       })
            //                       .catch((err) => {
            //                         console.log(err)
            //                         this.checkingVerificationCode = false;
            //                         this.error = err.message;
            //                       });
        } else if(this.emailLogin && this.verificationId) {

            const phoneAuthCredential = await this.$firebase.auth.PhoneAuthProvider.credential(this.verificationId, this.verification);
            console.log(phoneAuthCredential);
            
            const multiFactorAssertion = await this.$firebase.auth.PhoneMultiFactorGenerator.assertion(phoneAuthCredential);
            console.log(multiFactorAssertion);
            credential = await this.multifactorResolver.resolveSignIn(multiFactorAssertion);
            
            console.log(credential)
            th.$router.replace({ name: "App" });


        }

      }

    },
    async signInWithPhoneAndPassword() {
      return new Promise((resolve, reject) => {
        const phoneNumber = this.phone
        const password = this.password
        let data = {
          phoneNumber,
          password
        }

        axios.patch('users/sign-in-with-phone-and-password', data).then(response =>{
              console.log(response);
              resolve(response)
          }).catch(error => {
            console.log(error.response)
            this.error = error.response.data.message
            this.sendingVerificationCode = false
            //this.error = 'There is no user record corresponding to the provided identifier'
            reject(error.response)

          })
      })
    },
    async signInWithEmailAndPassword(response) {
      var auth = this.$firebase.auth()
      return new Promise((resolve, reject) => {
           auth
              .signInWithEmailAndPassword(response.data.email, this.password)
              .then((data) => {
                console.log(data);
                let th = this;
                
                th.$router.replace({ name: "App" });
                resolve()
              
              }).catch(async (error) => {
                console.log(error)
                this.error = error.message
                reject(error)
              })
      })
    },

    async submit() {
      if(this.smsSenderCounter > 0) {
       this.resetSmsCodeSending()
      }
      if(this.validate()) {
        this.error = null
        const auth = this.$firebase
          .auth()
        if(this.emailLogin) {
          let resolver;
          let multiFactorHints;
          let th = this
          auth
          .signInWithEmailAndPassword(this.email, this.password)
          .then((data) => {
            console.log(data);
            let th = this;
            th.$router.replace({ name: "App" });
           
          })
          .catch(async (error) => {
            console.log(error)
            if (error.code == 'auth/multi-factor-auth-required') {
              console.log(th.$firebase.auth())
              
              //resolver = user.getMultiFactorResolver(auth, error);
              // Show UI to let user select second factor.
              multiFactorHints = error.resolver.hints;
              th.multifactorResolver = error.resolver
              console.log(error.resolver)
              console.log(multiFactorHints)

              const appVerifier = window.recaptchaVerifier;
              const selectedHint = multiFactorHints[0]
              const phoneAuthProvider = new this.$firebase.auth.PhoneAuthProvider(th.$firebase.auth());
              console.log(phoneAuthProvider)
              const phoneInfoOptions = {
                multiFactorHint: selectedHint,
                session: th.multifactorResolver.session
              };
              const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, appVerifier);
              console.log(verificationId)
              th.verificationId = verificationId

            } else {
              // Handle other errors.
              this.error = error.message;
            }
           
          });
        } else {
          this.smsSenderCounter++
          this.sendingVerificationCode = true
          const phoneExists = await this.signInWithPhoneAndPassword()
          console.log(phoneExists)
          
          // const signIn = await this.signInWithEmailAndPassword(response)
          // console.log(signIn)

          if(phoneExists.data.email) {

            
            const phoneNumber = this.phone
            const password = this.password
            const appVerifier = window.recaptchaVerifier;
            console.log(appVerifier)
             
            this.$firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
                .then((confirmationResult) => {
                  /// SMS sent. Prompt user to type the code from the message, then sign the
                  /// user in with confirmationResult.confirm(code).
                  console.log(confirmationResult)
                  window.confirmationResult = confirmationResult;
                  this.smsSent = true
                  this.sendingVerificationCode = false
                  /// ...
                }).catch((error) => {
                  /// Error; SMS not sent
                  /// ...
                  console.log(error)
                  grecaptcha.reset(window.recaptchaWidgetId);
                  this.smsSent = false
                  this.sendingVerificationCode = false
    
                });
          }

        }


      } else {
        this.$v.$touch()
      }
      
    },
  },
  watch: {
    'emailLogin': {
          handler: function(val,oldVal) {
              this.phoneLogin = !val
          }
      },
      'phoneLogin': {
          handler: function(val,oldVal) {
              this.emailLogin = !val
          }
      }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    
  },

  validations: {
               
    email: {
        required: requiredIf(function(vue){
            return this.emailLogin ? true : false ;

        }),
        email
    },
    password: {
      required: requiredIf(function(vue){
          return !this.emailLogin ? true : false ;

      }),
        minLength: minLength(6)
    },
    recaptcha: {
      required
    },
    phone: {
      required: requiredIf(function(vue){
          return !this.emailLogin ? true : false ;

      })
    },
    verification: {
      required: requiredIf(function(vue){
          return (!this.emailLogin && this.smsSent) || (this.emailLogin && this.verificationId) ? true : false ;

      }),
      minLength: minLength(6)
    }
          
      
  }
};
</script>
